import React from "react"
import ContactUsForm from "../components/Forms/ContactUsForm"
import SEO from "../components/utils/seo"
import { graphql } from "gatsby"
import { get } from "lodash"
import { MaxWidthInnerContainer } from "../styles/globalCss"
import { HeaderText, BodyText } from "../components/Partials/TextPieces"
import BackgroundImage from "../components/Partials/BackgroundImage"
import WideImage from "../components/Partials/WideImage"
import CollageThreeRows from "../components/Partials/CollageThreeRows"
import Spacer from "../components/Partials/Spacer"

const History = ({ data }) => {
    const { fluid: bkgImg } = get(
        data,
        "contentfulHistoryPage.backgroundImage",
        {}
    )
    const { description: bkgImgDescription } = get(
        data,
        "contentfulHistoryPage.backgroundImage"
    )
    const { fluid: wideImg } = get(data, "contentfulHistoryPage.heroImage", {})
    const { description: wideImgDescription } = get(
        data,
        "contentfulHistoryPage.heroImage"
    )
    const { mainHeader } = get(data, "contentfulHistoryPage")
    const pageDescription = get(
        data,
        "contentfulHistoryPage.pageDescription.childMarkdownRemark.html"
    )
    const collage = get(data, "contentfulHistoryPage.collage", {})

    return (
        <>
            <SEO title="History" />
            <BackgroundImage bkgImg={bkgImg} description={bkgImgDescription} />
            <WideImage img={wideImg} description={wideImgDescription} />
            <MaxWidthInnerContainer>
                <HeaderText copy={mainHeader} isH1 />
                <BodyText copy={pageDescription} padding />
                <Spacer size="medium" />
                <CollageThreeRows imgs={collage} />
                <Spacer size="large" />
            </MaxWidthInnerContainer>
            <ContactUsForm bkgImg={bkgImg} description={bkgImgDescription} />
        </>
    )
}

export default History

export const query = graphql`
    query {
        contentfulHistoryPage {
            backgroundImage {
                description
                fluid(maxWidth: 1440, quality: 75) {
                    ...GatsbyContentfulFluid
                }
            }
            collage {
                bottomLeftImage {
                    description
                    fluid(maxWidth: 400, quality: 75) {
                        ...GatsbyContentfulFluid
                    }
                }
                bottomRightImage {
                    description
                    fluid(maxWidth: 700, quality: 75) {
                        ...GatsbyContentfulFluid
                    }
                }
                middleImage {
                    description
                    fluid(maxWidth: 1100, quality: 75) {
                        ...GatsbyContentfulFluid
                    }
                }
                topLeftImage {
                    description
                    fluid(maxWidth: 600, quality: 75) {
                        ...GatsbyContentfulFluid
                    }
                }
                topRightImage {
                    description
                    fluid(maxWidth: 600, quality: 75) {
                        ...GatsbyContentfulFluid
                    }
                }
            }
            heroImage {
                description
                fluid(maxWidth: 14400, quality: 75) {
                    ...GatsbyContentfulFluid
                }
            }
            mainHeader
            pageDescription {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
`
