import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { maxW } from "../../styles/mediaQueries"

const CollageThreeRows = ({ imgs }) => {
    const {
        topLeftImage,
        topRightImage,
        middleImage,
        bottomLeftImage,
        bottomRightImage
    } = imgs

    return (
        <CollageContainer>
            <TopLeftImg
                fluid={topLeftImage.fluid}
                alt={topLeftImage.description}
            />
            <TopRightImg
                fluid={topRightImage.fluid}
                alt={topRightImage.description}
            />
            <MiddleImg
                fluid={middleImage.fluid}
                alt={middleImage.description}
            />
            <BottomLeftImg
                fluid={bottomLeftImage.fluid}
                alt={bottomLeftImage.description}
            />
            <BottomRightImg
                fluid={bottomRightImage.fluid}
                alt={bottomRightImage}
            />
        </CollageContainer>
    )
}

export default CollageThreeRows

const CollageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`
const TopLeftImg = styled(Img)`
    width: 50%;
    margin-bottom: 10px;
    margin-right: 10px;
    ${maxW[0]} {
        width: 100%;
        max-height: 265px;
        margin-right: 0;
    }
`
const TopRightImg = styled(Img)`
    width: calc(50% - 10px);
    margin-bottom: 10px;
    ${maxW[0]} {
        width: 100%;
        max-height: 265px;
    }
`
const MiddleImg = styled(Img)`
    width: 100%;
    margin-bottom: 10px;
    ${maxW[0]} {
        width: 100%;
        max-height: 265px;
    }
`
const BottomLeftImg = styled(Img)`
    width: 30%;
    margin-right: 10px;
    ${maxW[0]} {
        width: 100%;
        max-height: 265px;
        margin-right: 0;
        margin-bottom: 10px;
    }
`
const BottomRightImg = styled(Img)`
    width: calc(70% - 10px);
    ${maxW[0]} {
        width: 100%;
        min-height: 265px;
    }
`
